import { FC, memo } from 'react';
import { APIProvider, Map, AdvancedMarker, Pin } from '@vis.gl/react-google-maps';

const getGoogleAPIKey = () => import.meta.env.VITE_APP_GOOGLE_MAPS_API_KEY;
const getGoogleMapId = () => import.meta.env.VITE_APP_GOOGLE_MAP_ID;

export interface LocationPickerProps {
  location?: { lat: number; lng: number };
  onChange?: (location: { lat: number; lng: number }) => void;
}
const LocationPicker: FC<LocationPickerProps> = ({ location, onChange }) => {
  if (!location?.lat && !location?.lng) {
    location = { lat: 17.642755, lng: -101.555664 };
  }

  const onSelect = (event: any) => {
    onChange?.({ lat: event.latLng.lat(), lng: event.latLng.lng() });
  };

  return (
    <APIProvider apiKey={getGoogleAPIKey()}>
      <Map
        style={{ width: '100%', height: '400px' }}
        defaultCenter={location}
        defaultZoom={15}
        gestureHandling={'greedy'}
        fullscreenControl={false}
        streetViewControl={false}
        mapTypeControl={true}
        mapId={getGoogleMapId()}
      >
        {location && (
          <AdvancedMarker onDragEnd={onSelect} draggable={true} position={location}>
            <Pin background={'#FC6634'} glyphColor={'#000'} borderColor={'#000'} />
          </AdvancedMarker>
        )}
      </Map>
    </APIProvider>
  );
};

export default memo(LocationPicker);
